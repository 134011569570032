import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import {particlesConfig} from "./config/particles-config"
// import { particlesOptions } from "./particlesConfig";

function ParticlesBackground() {
  const particlesInit = (engine) => {
    loadFull(engine);
  };

  return (
    <div className="App">
      <Particles init={particlesInit} options={particlesConfig} />
    </div>
  );
}
export default ParticlesBackground;
